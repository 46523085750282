import React, { Component } from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import '../../assets/css/body.css'
import { Link } from 'react-router-dom';
import GOBALS from '../../GOBALS';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Banner from '../Banner'

import PromotionModel from '../../models/PromotionModel';

const promotion_model = new PromotionModel();

export default class view extends Component {
    constructor(props) {
        super(props)
        this.state = {
            promotions: []
        };
    }

    componentDidMount() {
        AOS.init()
        this._fetchData()
    }

    async _fetchData() {
        const promotions = await promotion_model.getPromotionBy({ promotion_detail_language: sessionStorage.getItem('language') });
        this.setState({
            promotions: promotions.data.result
        })
    }


    render() {
        const { promotions } = this.state
        return (
            <div>
                <div style={{ marginTop: '42px' }}>
                    <Banner
                        page="Dealers"
                    />
                </div>
                <div className="white-navigation">
                    <Link to="/home">
                        <div>Home</div>
                    </Link>
                    <div>/</div>
                    <div>Dealers </div>
                </div>
                <Container>
                    <Row>
                        <Col className="title">
                            Dealers
                        </Col>
                    </Row>
                    <Row>
                        <Col className="title">
                            {
                            promotions.length > 0 ? 
                            <div className="description-promotion" dangerouslySetInnerHTML={{ __html: promotions[0].promotion_detail_description }}>
        
                            </div> 
                            : 
                            <div style={{align:"center",color:"red",paddingBottom:32}}></div>
                            }
                        </Col>
                    </Row>
{/*

                    {promotions.map((item, no) => (
                        <Row
                            key={no}
                            className="row-promotion"
                            data-aos='fade-up' data-aos-duration="1000"
                        >
                            <Card style={{ width: '100%', height: '100%' }}>
                                <Card.Header style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                                    {item.promotion_detail_name}
                                </Card.Header>
                                {item.promotion_image === undefined || item.promotion_image === "" ?
                                    null :
                                    <Card.Img
                                        variant="top"
                                        src={`${GOBALS.URL_IMG}${item.promotion_image}`}
                                        className="image-promotion-list"
                                    />
                                }

                                <Card.Body style={{ backgroundColor: '#fff', height: '100%' }}>
                                    <div
                                        className="description-promotion"
                                        dangerouslySetInnerHTML={{ __html: item.promotion_detail_description }}
                                    />
                                    <Link to={`/dealers/detail/${item.promotion_code}`} style={{ width: '100%' }}>
                                        <Button
                                            style={{
                                                float: "right",
                                                backgroundColor: "#ad3235",
                                                borderColor: "#ad3235"
                                            }}
                                            className="button-card-style"
                                        >
                                            Read more
                                        </Button>
                                    </Link>
                                </Card.Body>
                            </Card>
                        </Row>
                    ))}

*/}
                </Container>
            </div>
        )
    }
}
